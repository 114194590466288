/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { getAppPath } from 'src/contracts/applications'
import { useRoutesMatching } from 'src/hooks/useRoutesMatching'
import { ApplicationData } from 'src/components/open-opportunities/ApplicationWizard/redux/types'
import { getOpenOpportunitiesApplications } from 'src/api/open-opportunities/api'
import { Application } from 'src/contracts/open-opportunities/contracts'
import { getOpportunitySummary } from 'src/api/opportunities-manager/api'
import { OpportunityLocationTypeV2Enum, OpportunitySummary as OpportunitySummaryType } from 'src/contracts/opportunities-manager/contracts'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { useLogger } from 'src/utils/useLogger'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Icon } from '../Icon'
import { FieldLabel } from '../inputs/input-field/FieldLabel'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { useNotifications } from '../notification/NotificationProvider'
import { InviteToOpportunityModal } from '../profile/InviteToOpportunityModal'
import { CountryCitiesList } from './CountryCitiesList'
import { OfferBadges } from './OfferBadges'
import { locationLabel } from './utils'

type OpportunitySummaryProps = {
    opportunityId: string
    opportunity?: Nullable<OpportunitySummaryType>
    applicationsData?: Array<ApplicationData>
    isVMCCloud?: boolean
}

const OpportunitySummary: FunctionComponent<React.PropsWithChildren<OpportunitySummaryProps>> = ({
    opportunityId,
    opportunity,
    applicationsData,
    isVMCCloud = false,
}) => {
    const log = useLogger('error')
    const { addError } = useNotifications()
    const { applicationId } = useParams()
    const [opportunitySummary, setOpportunitySummary] = useState<Nullable<OpportunitySummaryType>>(opportunity || null)
    const [inviteToOpportunityModal, setInviteToOpportunityModal] = useState(false)
    const theme = useTheme()

    const { pathname } = useLocation()

    const isSmeCloudApp = useMemo(() => pathname.includes('opportunities-manager-sme'), [pathname])

    const appPath = useMemo(
        () =>
            isSmeCloudApp
                ? getAppPath('OPPORTUNITIES_MANAGER_SME')
                : isVMCCloud
                ? getAppPath('VACANCIES_MANAGER')
                : getAppPath('OPPORTUNITIES_MANAGER'),
        [isSmeCloudApp, isVMCCloud],
    )

    const [applications, setApplications] = useState<Nullable<Array<Application>>>(
        applicationsData?.map(item => ({ ...item, opportunityId, statusHistory: [] })) || null,
    )
    const [isFetchingApplications, setFetchingApplications] = useState<boolean>(false)

    const loadApplications = useCallback(() => {
        if (opportunityId) {
            setFetchingApplications(true)
            getOpenOpportunitiesApplications(opportunityId)
                .then(response => {
                    setFetchingApplications(false)
                    setApplications(response.applications.filter(application => application.appliedOn))
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, opportunityId])

    useEffect(() => {
        if (!opportunity) {
            getOpportunitySummary(opportunityId)
                .then(summary => {
                    setOpportunitySummary(summary)
                    loadApplications()
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, loadApplications, log, opportunityId, opportunity])

    const {
        matchOpportunitiesManagerApplicationDetails,
        matchOpportunitiesManagerSMEApplicationDetails,
        matchOpportunitiesManagerVMCApplicationDetails,
    } = useRoutesMatching()

    const linkToFullOpportunity = useMemo(
        () =>
            isSmeCloudApp
                ? matchOpportunitiesManagerSMEApplicationDetails
                    ? `${appPath}/opportunities/${opportunityId}`
                    : `/open-opportunities-sme/${opportunityId}`
                : isVMCCloud
                ? matchOpportunitiesManagerVMCApplicationDetails
                    ? `${appPath}/opportunities/${opportunityId}`
                    : `/open-vacancies/${opportunityId}`
                : matchOpportunitiesManagerApplicationDetails
                ? `${appPath}/opportunities/${opportunityId}`
                : `/open-opportunities/${opportunityId}`,
        [
            appPath,
            isVMCCloud,
            isSmeCloudApp,
            matchOpportunitiesManagerSMEApplicationDetails,
            matchOpportunitiesManagerVMCApplicationDetails,
            matchOpportunitiesManagerApplicationDetails,
            opportunityId,
        ],
    )

    const onSite =
        opportunitySummary?.location.locationTypeV2 && opportunitySummary.location.locationTypeV2 !== OpportunityLocationTypeV2Enum.REMOTE

    return opportunitySummary ? (
        <Fragment>
            <FlexBox
                css={css`
                    .applied-dropdown {
                        right: 0;
                    }
                `}
            >
                <OfferBadges
                    vacancies={opportunitySummary.vacancies}
                    offerType={opportunitySummary.opportunityOfferType}
                    applied={opportunitySummary.appliedWith || 0}
                    appliedOptions={applications?.length ? applications : undefined}
                    isLoadingApplied={isFetchingApplications}
                />
            </FlexBox>
            <h4
                css={css`
                    margin: 16px 0 6px;
                `}
            >
                {opportunitySummary.opportunityTitle}
            </h4>
            <Divider color={theme.colors.beige_3} />
            <Tooltip title='Project dates'>
                <FlexBox
                    alignItems='center'
                    css={css`
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 24px;
                        width: fit-content;
                    `}
                >
                    <Icon
                        name='violet-calendar'
                        size={24}
                        css={css`
                            margin-right: 12px;
                        `}
                    />
                    {convertTimestampToDateString(opportunitySummary.startDate)} –{' '}
                    {convertTimestampToDateString(opportunitySummary.endDate)}
                </FlexBox>
            </Tooltip>
            <Tooltip title='Experience'>
                <FlexBox
                    alignItems='center'
                    css={css`
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 24px;
                        width: fit-content;
                    `}
                >
                    <Icon
                        name='experience'
                        size={24}
                        css={css`
                            margin-right: 12px;
                        `}
                    />
                    {opportunitySummary.minYearsOfExperience} years
                </FlexBox>
            </Tooltip>
            <Tooltip title='Industry'>
                <FlexBox
                    alignItems='center'
                    css={css`
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 16px;
                        width: fit-content;
                    `}
                >
                    <Icon
                        name='code-editor'
                        size={24}
                        css={css`
                            margin-right: 12px;
                        `}
                    />
                    {opportunitySummary.projectIndustry}
                </FlexBox>
            </Tooltip>
            <Divider color={theme.colors.beige_3} />
            <div
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <FieldLabel
                    label={`${locationLabel[opportunitySummary.location.locationTypeV2 || OpportunityLocationTypeV2Enum.REMOTE]}:`}
                    styles={css`
                        display: inline;
                        margin: 0 8px 0 0;
                    `}
                />
                {onSite ? (
                    <CountryCitiesList countryCitiesList={opportunitySummary.location.countriesWithCities} />
                ) : (
                    opportunitySummary.location.regions
                        .concat(opportunitySummary.location.countriesWithCities.map(place => place.country))
                        .join(', ') || 'Anywhere'
                )}
            </div>
            <FlexBox
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <FieldLabel
                    label='Deal size:'
                    styles={css`
                        margin: 0 8px 0 0;
                    `}
                />
                <strong>
                    {!opportunitySummary.minDealSize || !opportunitySummary.maxDealSize
                        ? 'Negotiable'
                        : `${opportunitySummary.minDealSize?.toLocaleString('en')} – ${opportunitySummary.maxDealSize?.toLocaleString(
                              'en',
                          )} ${opportunitySummary.currency}`}
                </strong>
            </FlexBox>
            <FlexBox>
                <FieldLabel
                    label='Rate:'
                    styles={css`
                        margin: 0 8px 0 0;
                    `}
                />
                <strong>
                    {!opportunitySummary.minRate || !opportunitySummary.maxRate
                        ? 'Negotiable'
                        : `${opportunitySummary.minRate?.toLocaleString('en')} – ${opportunitySummary.maxRate?.toLocaleString('en')} ${
                              opportunitySummary.currency
                          }/h`}
                </strong>
            </FlexBox>
            <Divider color={theme.colors.beige_3} />

            <Link to={linkToFullOpportunity}>
                <Button variant='linkForm'>
                    <FlexBox alignItems='center'>
                        {isVMCCloud ? 'See full vacancy' : 'See full opportunity'}
                        <Icon name='arrow-right-single' />
                    </FlexBox>
                </Button>
            </Link>
            <div>
                <Button
                    variant='text'
                    style={css`
                        padding-left: 0;
                    `}
                    onClick={() => setInviteToOpportunityModal(true)}
                >
                    <Icon
                        style={css`
                            margin-right: 4px;
                        `}
                        name='plane'
                    />
                    {isVMCCloud ? 'Invite to an vacancy' : 'Invite to an opportunity'}
                </Button>
            </div>
            <InviteToOpportunityModal
                onClose={() => setInviteToOpportunityModal(false)}
                opened={inviteToOpportunityModal}
                isCandidateView={true}
                applicationId={applicationId}
                title='Select the vacancy'
                description="Please select an vacancy and the platform will send an invite to this specialist's manager."
                selectPlaceholder='Select vacancy'
            />
        </Fragment>
    ) : null
}

export { OpportunitySummary }
